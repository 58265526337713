$('.sliderNomal').slick({
    arrows: false,
    autoplay: true,
    autoplaySpeed: 3000,
    speed: 1000,
    pauseOnFocus: false,    //フォーカスで止めない  
    pauseOnHover: false,    //ホバーで止めない  
    pauseOnDotsHover: false,//ドットで止めない 
    dots: false,
    fade: true,
    cssEase: 'linear',
    adaptiveHeight: true,
});